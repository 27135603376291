import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import PledgeForm from "../components/Students/PledgeForm"
import pledgeImg from "../images/pledge-img.png"

const Pledge = () => {
  return (
    <Layout>
      <StyleWrapper className="white-background">
        <h1 className="mb-2 mb-md-5 d-flex justify-content-center">
          Sign the upReach Pledge
        </h1>
        <div className="row py-5 px-3" style={{ backgroundColor: "#464FD0" }}>
          <div className="col-sm-6 col-12 order-last order-sm-first text-center-align">
            <p className="subtext">
              Do <span className="highlight">YOU</span> think it is{" "}
              <span className="highlight">FAIR</span> that a student from a
              disadvantaged background who gains a{" "}
              <span className="highlight">first class degree</span> from a top
              university is
              <span className="highlight"> less likely</span> to secure an elite
              job than a more privileged student with a{" "}
              <span className="highlight">2.2?</span>{" "}
            </p>
            <p className="subtext highlight">
              Help us create a level playing field by signing the upReach Pledge
            </p>
            <p className="font-20px">
              <i>
                <span className="highlight">“</span>I believe that everyone
                should have an equal opportunity to realise their academic and
                career potential regardless of social background.
                <span className="highlight">”</span>
              </i>
            </p>
            <p className="subtext">
              To sign the pledge, simply enter your details below:
            </p>
          </div>
          <div className="col-sm-6 col-12 order-first order-sm-last pb-sm-0 pb-3">
            <img src={pledgeImg} className="img-fluid mx-auto" />
          </div>
        </div>
        <PledgeForm />
      </StyleWrapper>
    </Layout>
  )
}

export default Pledge

export const StyleWrapper = styled.div`
  width: 100%;
  padding: 4% 8%;
  overflow: scroll;
  @media (max-width: 600px) {
    width: 100%;
    padding-top: 8%;
    padding-bottom: 8%;
  }

  .text-center-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-control {
    height: 69px;
    width: 70%;
    background-color: #ececec;
    @media (max-width: 600px) {
      height: 50px;
      width: 100%;
    }
  }
  .heading {
    font-weight: 500;
  }

  .form-label {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 20px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .font-20px {
    color: white;
    font-size: 20px;
  }

  .flatButton {
    height: 50px;
    width: 150px;
    background-color: #e43d5a;
    border: none;
  }

  .form-check {
    margin-top: 10px;
  }

  .form-check-input {
    transform: scale(1.2);
  }

  .cancel-btn {
    font-size: 16px;
    font-weight: bold;
    color: #464fd0;
    cursor: pointer;
    position: relative;
    top: 8px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .checkbox-link {
    display: inline-block;
    color: black;
    text-decoration: underline;
  }

  .fail-text {
    padding-top: 10px;
    padding-right: 80px;
    font-weight: bolder;
    color: #e43d5a;
  }

  .MuiFormGroup-root {
    display: flex !important;
    flex-direction: row !important;
  }

  .required-text {
    color: #e43d5a;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .labelTitle {
    font-weight: 600;
    font-size: 20px;
    @media (min-width: 2000px) {
      font-size: 24px;
    }
  }

  @media (min-width: 991px) {
    .eligibilityLabel {
      width: 70%;
    }
  }

  .anchorLabel {
    text-decoration: underline;
    font-weight: 600;
  }

  .thank-you-message {
    font-size: 20px;
    height: 40vh;
    font-weight: bold;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .toast-bar {
    color: white;
    background-color: #464fd0;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .toast-bar-progress {
    background: #00bfff;
  }

  .subtext {
    font-size: 18px;
    color: white;
  }

  .highlight {
    color: gold;
  }
`
