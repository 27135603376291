import React, { useState, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

const Pledge = () => {
  const { register, handleSubmit, reset, errors } = useForm({})
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  const [universities, setUniversities] = useState([])
  const [firstUniversity, setFirstUniversity] = useState(0)
  const [error, setError] = useState("")
  const [disableBtn, setDisableBtn] = useState(false)
  const [colleges, setColleges] = useState([])
  const [campuses, setCampuses] = useState([])
  const [student, setStudent] = useState()
  const [showForm, setShowForm] = useState(true)
  const [eligibility, setEligibility] = React.useState()

  const newLabel = (
    <div className="eligibilityLabel">
      <h4 className="m-0 pb-2 labelTitle">
        I would like to sign the upReach pledge.{" "}
      </h4>
      <p className="m-0 pb-2">
        I understand I will receive an email confirming I have signed, with
        occasional emails (roughly quarterly) to update me about the upReach
        Pledge, and about social mobility news more generally.{" "}
      </p>
      <p className="m-0 pb-4">
        I can unsubscribe at any time. upReach will NOT share your data with any
        other organisation as a result of signing the upReach Pledge.{" "}
        <a href="/privacy-notice-and-use-of-cookies" className="anchorLabel">
          upReach's Privacy Notice
        </a>
      </p>
    </div>
  )

  useEffect(() => {
    fetchUniversities()
  }, [])
  useEffect(() => {
    async function fetchUniversityData() {
      if (
        firstUniversity === 30 ||
        firstUniversity === 46 ||
        firstUniversity === 111 ||
        firstUniversity === 57
      ) {
        const response = await fetch(
          `${enkiUrl}/universities/get-info/${firstUniversity}`
        )
        const responseJson = await response.json()
        if (firstUniversity === 30 || firstUniversity === 57)
          setColleges(responseJson.university.college)
        else if (firstUniversity === 46 || firstUniversity === 111)
          setCampuses(responseJson.university.campus)
      }
    }
    fetchUniversityData()
  }, [firstUniversity])

  async function fetchUniversities() {
    try {
      const response = await fetch(
        `${enkiUrl}/universities/get-active-universities`
      )
      const responseJson = await response.json()
      let universities = responseJson.universities
      universities.sort((a, b) => a.name > b.name)
      universities.splice(0, 0, {
        id: null,
        name: "Select University",
        short_name: "none",
      })
      setUniversities(universities)
      console.log(universities)
    } catch (error) {
      console.log(error)
    }
  }

  const handleStudent = e => {
    if (e.target.value === "Yes") {
      setStudent(true)
      setError("")
    } else if (e.target.value === "No") {
      setStudent(false)
      setError("")
    } else {
      setStudent()
    }
  }

  const submitData = data => {
    if (data["student_at_Uk_univeristy"] === "null") {
      setError("Please select whether you are a student at a UK University")
      return
    }
    if(student){
      const reg = /.ac.uk$/
      if (reg.test(data["university_email"]) === false) {
        setError("Please enter a valid University email address")
        return
      }
      if (data["university_id"] === "Select University") {
        setError("Please select a University")
        return
      }
      if (data["graduation_year"] === "null") {
        setError("Please select a Graduation Year")
        return
      }
      if (data["upreach_associate"] === "null") {
        setError("Please select whether you are an upReach Associate")
        return
      }
    }

    fetch(`https://formspree.io/f/xvodladw`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status == 200) {
          try {
            fetch(`${process.env.GATSBY_UPREACH_ORG_SERVER_URL}/sendToSendGridEmail`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
          } catch (error) {
            console.log(error)
          }
          setShowForm(false)
          reset()
          setError("")
          setDisableBtn(true)
          setEligibility(false)
        } else {
          return response.json().then(data => setError(data.message))
        }
      })
      .catch(err => {
        console.log(err)
        setDisableBtn(false)
      })
  }

  return showForm === true ? (
    <div>
      <Form onSubmit={handleSubmit(submitData)}>
        <Form.Label>
          First Name<span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="first_name"
          ref={register({ required: true })}
        />

        <Form.Label>
          Surname<span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="surname"
          ref={register({ required: true })}
        />

        <Form.Label>
          Are you a student at a UK university?{" "}
          <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          as="select"
          name="student_at_Uk_univeristy"
          ref={register({ required: true })}
          onChange={handleStudent}
        >
          <option value="null">Select Option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Control>
        {student === true ? (
          <div>
            <Form.Label>
              University Email Address<span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              name="university_email"
              ref={register({ required: true })}
            />

            <Form.Label>
              University<span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              ref={register({ required: true })}
              name="university_id"
              isInvalid={errors && errors.university}
              as="select"
              onChange={e => setFirstUniversity(Number(e.target.value))}
            >
              {universities &&
                universities.length > 0 &&
                universities.map(university => (
                  <option value={university.id}>{university.name}</option>
                ))}
            </Form.Control>

            {(firstUniversity === 30 || firstUniversity === 57) && (
              <div>
                <Form.Label>
                  College<span className="required-text">*</span>
                </Form.Label>
                <Form.Control
                  ref={register({ required: true })}
                  name="college_id"
                  isInvalid={errors && errors.college_id}
                  as="select"
                >
                  {colleges &&
                    colleges.length > 0 &&
                    colleges.map(college => (
                      <option value={college.id}>{college.name}</option>
                    ))}
                </Form.Control>
              </div>
            )}

            {(firstUniversity === 46 || firstUniversity === 111) && (
              <div>
                <Form.Label>
                  Campus<span className="required-text">*</span>
                </Form.Label>
                <Form.Control
                  ref={register({ required: true })}
                  name="campus_id"
                  isInvalid={errors && errors.campus_id}
                  as="select"
                >
                  {campuses &&
                    campuses.length > 0 &&
                    campuses.map(campus => (
                      <option value={campus.id}>{campus.name}</option>
                    ))}
                </Form.Control>
              </div>
            )}

            <Form.Label>
              Graduation Year<span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              name="graduation_year"
              ref={register({ required: true })}
            >
              <option value="null">Select Year</option>
              <option value={2022}>2022</option>
              <option value={2023}>2023</option>
              <option value={2024}>2024</option>
              <option value={2025}>2025</option>
              <option value={2026}>2026</option>
              <option value={2027}>2027</option>
              <option value={2028}>2028</option>
            </Form.Control>

            <Form.Label>
              Are you an upReach Associate?{" "}
              <span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              name="upreach_associate"
              ref={register({ required: true })}
            >
              <option value="null">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Control>
          </div>
        ) : student === null ? (
          <></>
        ) : (
          <></>
        )}
        {student === false ? (
          <div>
            <Form.Label>
              Email Address<span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              ref={register({ required: true })}
            />

            <Form.Label>Organisation</Form.Label>
            <Form.Control
              type="text"
              name="organisation"
              ref={register({ required: true })}
            />
          </div>
        ) : student === null ? (
          <></>
        ) : (
          <></>
        )}

        <Form.Check
          className="py-3"
          required
          ref={register({ required: true })}
          name="form1_eligibility"
          checked={eligibility}
          label={newLabel}
          feedback="You must agree before submitting."
          onChange={() => setEligibility(!eligibility)}
          style={{ display: "inline-block" }}
        />

        <Button className="flatButton" disabled={disableBtn} type="submit">
          Submit
        </Button>
        {error != "" ? <p className="fail-text pt-3">{error}</p> : <></>}
      </Form>
    </div>
  ) : (
    <div className="thank-you-message">
      <p>
        Thank you for signing the upReach Pledge. Please check your email for
        next steps.
      </p>{" "}
    </div>
  )
}

export default Pledge
